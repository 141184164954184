<template>
  <profile-edit-create :organization-id="organization.id" />
</template>
<script>
import ProfileEditCreate from '~/pages/shared/profiles/partials/EditCreate';

export default {

  components: {
    ProfileEditCreate,
  },

  middleware: 'admin',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>
